<template lang="pug">
  v-form(@submit.prevent="onSubmitClick" ref="form" data-test="crm-login-form").auth-form
    v-text-field(
      label='Username'
      name="username"
      data-test="login"
      :rules="[RULES.REQUIRED]"
      v-model="model.formData.username"
      :error="formErrors.hasError('username')"
      :error-messages="formErrors.fields.username"
    )
    v-text-field(
      label='Password'
      type="password"
      name="password"
      data-test="password"
      :rules="[RULES.REQUIRED]"
      v-model="model.formData.password"
      :error="formErrors.hasError('password')"
      :error-messages="formErrors.fields.password"
    )
    v-btn(block color="primary" data-test='submit' type="submit" :disabled="loading").mt-4 Login
</template>

<script>
import { REQUIRED } from "@/util/validation-rules";

export default {
  name: 'LoginComponent',

  props: {
    model: {
      type: Object,
      required: true
    },
    loading: Boolean,
    formErrors: Object
  },

  data: () => ({
    RULES: {
      REQUIRED
    }
  }),

  methods: {
    onSubmitClick() {
      this.$emit('submit')
    },

    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>
